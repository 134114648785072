<template>
    <div>
        <v-card
            outlined
        >
            <v-sheet
                v-if="!uuid"
                class="pa-3"
            >
                <v-skeleton-loader
                    class="mx-auto"
                    max-width="100%"
                    type="table-heading,list-item-avatar@3"
                />
            </v-sheet>
            <media-table
                v-else
                hide-size
                shared-type="course"
                :shared-uuid="uuid"
                :hide-author="$auth.isTeacher()"
                :payload="mediaPayload"
                :editable="$auth.isTeacher()"
                viewable
            />
        </v-card>
    </div>
</template>

<script>
import MediaTable from '@apps/media/components/MediaTable'

export default {
    name: 'CourseDocs',
    components: {
        MediaTable
    },
    props: {
        uuid: {
            type: String,
            required: true
        }
    },
    computed: {
        mediaPayload() {
            if(!this.$auth.isTeacher()) {
                return {}
            }

            return {
                author: this.$auth.user.uuid
            }
        }
    },
    data() {
        return {}
    }
}
</script>

<style lang=scss>

</style>
